<template>
    <modal ref="modalVerificado" :titulo="titulo" icon="Alto" @guardar="handle()">
        <div class="row mx-0 justify-center my-3">
            <div class="col-10 text-center text-general">
                {{ mensaje }}
            </div>
        </div>
        <div class="row mx-0 justify-center">
            <div class="col-10">
                <p class="text-general f-14 pl-3">
                    Justificación
                </p>
                <el-input v-model="comentario" type="textarea" :rows="5" class="w-100" />
            </div>
        </div>
    </modal>
</template>

<script>
import entregasDineros from '~/services/almacen/entregas_dineros'
export default {
    data(){
        return {
            comentario: '',
            titulo: '',
            mensaje: '',
            estado: 3,
            id: 0
        }
    },
    methods: {
        toggle(id, estado){
            this.id = id;
            this.estado = estado;
            this.titulo = estado == 3 ? 'Verificar entrega': 'Anular Entrega'
            this.mensaje = estado == 3 ? '¿Aceptas que has recibido del dinero de este leechero?': 'Se anulará la entrega de dinero de este leechero'
            this.$refs.modalVerificado.toggle();
        },
        handle(){
            if (this.estado == 3){
                this.verificar()
            } else {
                this.anular()
            }
        },
        async anular(){
            try {
                const params = {
                    id: this.id,
                    comentario: this.comentario,
                    estado: 5
                }
                const { data } = await entregasDineros.anularWeb(params)
                if (data.exito == true){
                    this.notificacion('', 'Entrega anulada correctamente', 'success')
                    this.$emit('saved')
                    this.$refs.modalVerificado.toggle();
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        async verificar(){
            try {
                const params = {
                    id: this.id,
                    comentario: this.comentario,
                }
                const { data } = await entregasDineros.verificarWeb(params)
                if (data.exito == true){
                    this.notificacion('', 'Entrega verificada correctamente', 'success')
                    this.$emit('saved')
                    this.$refs.modalVerificado.toggle();
                }
            } catch (error){
                this.error_catch(error)
            }
        },

        
    }
}
</script>